body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}
body, html { overflow-x:auto !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-card-meta-title {
    color: #ffbd59 !important;
    font-size: 25px !important;
    white-space: pre-wrap !important;
    text-align: center;
    font-weight: bolder !important;
    word-wrap: break-word;
    max-height: fit-content;
}